@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Raleway&display=swap");

:root {
  --color-lightgrey: #f4f4f4;

}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #fff;
  font-size: 16px;
  line-height: 1.6;
  font-family: "Lora", serif;
  color: #111;
}

.row {
  display: flex;
  justify-content: space-between;
}


#hero,
#kids,
#projects,
#interesse,
#street,
#develop,
#mission,
#caroussel,
#featured,
#contact {
  position: relative;
  width: 1440px;
  max-width: 95%;
  min-height: 580px;
  /* height: 70vh; */
  margin: 30px auto;
  padding: 0 30px;
  background-color: var(--color-lightgrey);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#street {
  background-color: darkgray;
  animation: night 20s infinite;
}

#contact,
#caroussel {
  flex-direction: column;
  justify-content: space-around;
}

#caroussel {
  height: 780px;
  margin: 0px opx;
}

@keyframes night {
  0% {
    background-color: darkgray;
  }
  60% {
    background-color: #222222;
  }
  100% {
    background-color: darkgray;
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 2.4rem;
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: 100;
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 2.2rem;
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: bold;
}

h3 {
  margin: 0 auto;
  font-size: 1.5rem;
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: 100;
}

p {
  margin-bottom: 30px;
  font-size: 1.2rem;
}

button,
.btn {
  font-family: "Lora", serif;
  margin: 20px 5px;
  border: 1px solid #333;
  border-radius: 0;
  color: #333;
  padding: 10px;
  text-decoration: none;
  cursor: pointer;
}

button:hover,
.btn:hover {
  font-weight: bold;
}

span.leesmeer {
  padding-left: 10px;
  font-weight: bold;
  cursor: pointer;
}

span.leesmeer:hover {
  text-decoration: underline;
}

/* -----------hero--------------------- */
#hero {
  background-image: url("./assets/img/JK-lightgrey.png");
  background-repeat: no-repeat;
  background-size: auto 580px;
}

.hero__text {
  width: 55%;
}

.hero__text:after {
  content: "";
  display: block;
  width: 1px;
  height: 70px;
  background-color: #111;
}
#hero .img-jk {
  /* border: 2px solid green; */
  position: absolute;
  top: 0px;
  left: 0px;
  /* width: 100%; */
  height: 100%;
  object-fit: cover;
}

#hero .img-jook {
  /* border: 2px solid green; */
  position: absolute;
  bottom: -20px;
  right: 0px;
  width: 38%;
  object-fit: cover;
}

#hero p {
  max-width: 450px;
}

/* ---------------------------- */

.div__img {
  width: 45%;
}

.div__text {
  width: 50%;
}


#mission img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}







#coderjook {
  padding: 0 0;
}

#projects .div__text,
#interesse .div__text {
  width: 46%;
}
#projects,
#interesse {
  padding: 30px 30px;
}
#interesse h2:before,
#projects h2:before,
#kids h2:before,
#featured h2:before,
#develop h2:before,
#mission h2:before {
  content: "";
  position: relative;
  bottom: 15px;
  display: inline-block;
  width: 30px;
  height: 1px;
  margin-right: 1.5rem;
  background-color: #111;
}

/* --------------contact-------------------------- */

#contact .wrapper {
  flex-direction: column;
  justify-content: flex-start;
  margin: 30px auto;
}

#contact p {
  text-align: center;
}

#bubbles {
  background-color: black;
}

/* ======================mediaQueries============================*/

@media (max-width: 767px) {
  #hero,
  #kids,
  #projects,
  #interesse,
  #develop,
  #mission,
  #caroussel,
  #contact {
    max-width: 95%;
    height: 100%;
    flex-direction: column;
    margin: 1rem auto;
    padding: 0 10px;
  }

  h1 {
    margin: 20px auto;
    font-size: 2rem;
  }

  h2 {
    margin: 0.9rem auto;
    font-size: 1.7rem;
  }

  h3 {
    margin: 0.8rem auto;
    font-size: 1.3rem;
  }

  p {
    font-size: 0.9rem;
  }

  button,
  .btn {
    font-family: "Lora", serif;
    margin: 20px 5px;
    border: 1px solid #333;
    border-radius: 0;
    color: #333;
    padding: 10px;
    cursor: pointer;
  }

  button:hover,
  .btn:hover {
    font-weight: bold;
  }

  /* -----------hero--------------------- */
  #hero {
    align-items: flex-end;
  }

  #hero,
  #develop,
  #mission {
    min-height: 350px;
  }

  .hero__text {
    width: 85%;
    text-align: right;   
  }

  .hero__text:after {
    content: "";
    display: none;
  }

  #hero .hero__img {
    width: 100%;
    height: 260px;
    /* border: 2px solid green; */
  }

  #hero .img-jook {
    position: relative;
    width: 100%;
    height: 100%;
    bottom: 0px;
    object-position: left 20%;
    /* object-fit: cover; */
  }

  /* -------------kids, mission, projects --------------- */
  #mission .div__img {
    display: none;
  }

  #projects .div__text,
  #interesse .div__text,
  .div__text {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .div__img {
    width: 80%;
    text-align: center;
    margin-bottom: 30px;
  }

  #interesse h2:before,
  #projects h2:before,
  #kids h2:before,
  #featured h2:before,
  #mission h2:before {
    content: "";
    display: none;
  }

  h2:after {
    content: "";
    display: none;
  }
}
