body {
  background-color: #fff;
  font-size: 16px;
  line-height: 1.6;
  font-family: "Lora", serif;
  color: #111;
}

#caroussel img {
  width: 100%;
  /* height: 100%; */
}

#caroussel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.projectname {
  font-size: 1.1rem;
  font-family: "Raleway", Helvetica, sans-serif;
  font-weight: bold;
}

.slider {
  position: relative;
  width: 450px;
  height: 450px;
  margin: auto;
  overflow: visible;
}

.slide {
  position: absolute;
  width: 450px;
  height: 4500px;
  position: absolute;
  transition: 0.6s ease;
  transform: translate(-100%, 0);
}

.slide.active {
  width: 600px;
  height: 600px;
  top: -30px;
  left: -70px;
  transform: translate(0, 0);
  z-index: 100;
  cursor: pointer;
}

.slide.last {
  transform: translate(100%, 0);
}

/* ___________mediaQueries__________________ */

@media (max-width: 767px) {
  .slider {
    position: relative;

    width: 450px;
    height: 300px;
    margin: auto;
    overflow: visible;
  }

  .slide {
    position: absolute;

    width: 300px;
    height: 200px;
    position: absolute;
    transition: 0.6s ease;
    transform: translate(-100%, 0);
  }

  .slide.active {
    width: 450px;
    height: 300px;
    top: -30px;
    /* left: -0px; */
    transform: translate(0, 0);
    z-index: 100;
  }

  .slide.last {
    transform: translate(100%, 0);
  }
}