.contactform {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  justify-content: space-between;
  color: #111;
  font-family: "Raleway", Helvetica, sans-serif;
}

#contact form input[type="email"],
#contact form input[type="text"] {
  width: 48%;
  height: 40px;
  padding: 0 15px;
  border: 1px solid #111;
  border-radius: 0;
  background: transparent;
}

#contact form textarea {
  width: 100%;
  height: 90px;
  margin: 30px 0;
  padding: 10px 15px;
  border: 1px solid #111;
  border-radius: 0;
  background: transparent;
  resize: none;
}
/* 
#contact form input[type="submit"], */
.button {
  width: 100%;
  height: 40px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  background-color: #111;
  cursor: pointer;
  text-align: center;
  font-family: "Raleway", Helvetica, sans-serif;
}

.button:hover {
  background-color: darkgrey;
  color: black;
}
