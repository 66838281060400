.imageslider {
  height: 40vh;
  border: 3px solid white;
  display: flex;
  /* keep them in the same row */
  flex-wrap: nowrap;
  /* only first slide has to be visible */
  overflow-x: hidden;
  position: relative;
}

.imageslide {
  height: 100%;
  width: 100%;
  /* no shrinking !*/
  flex-shrink: 0;
  background-position: center;
  background-size: cover;
  transition: 750ms all ease-in-out;
}
